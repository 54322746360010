<template>
  <div :class="'flex-wrapper-app'">
    <!-- <Alert /> -->
    <Navbar />
    <main :class="'search-main'">
      <div class="container">
        <Loader v-if="isLoading" />
        <div>
          <h1 class="search-title">Εξυπηρέτηση σχετικά με την αποστολή</h1>

          <div class="search-title">
            <h4><span>Τηλέφωνο: </span> &nbsp; <a href="tel:2111982107">211 198 2107</a></h4>
            <h4>
              <span>Email: </span> &nbsp;
              <a href="mailto:help@skroutzdelivery.gr">help@skroutzdelivery.gr</a>
            </h4>
          </div>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../pages-sections/Navbar.vue';
import Footer from '../pages-sections/Footer.vue';
import Loader from '../loader/Loader.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    Loader,
  },
  watch: {
    $route: 'checkUrlHash',
  },
  mounted() {
    this.checkUrlHash();
  },
  methods: {
    checkUrlHash() {
      if (window.location.hash.length > 1) {
        const trackId = window.location.hash.replace('#', '');
        this.requestTracking(trackId);
      } else {
        this.isTrackIdValid = false;
      }
    },
  },
};
</script>

<style lang="scss">
.flex-wrapper-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  background-repeat: repeat no-repeat !important;
}
.search-main {
  flex-grow: 1;
  display: flex;
  background: url('../../assets/images/graphic-bg-search-page.svg');
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 733px) {
  .search-main {
    padding: 56px 0 224px;
  }
  main {
    background-position-x: 35% !important;
  }
}
@media screen and (min-width: 733px) and (max-width: 961px) {
  .search-main {
    padding: 136px 0 433px;

    .container {
      margin: 0 59px;
    }
  }
}
@media screen and (max-width: 961px) {
  .search-main {
    background: url('../../assets/images/graphic-bg-search-page.svg') bottom center;
    background-size: contain;
    justify-content: center;
    width: 100%;

    .container {
      width: 100%;
    }
  }
}
@media screen and (min-width: 961px) {
  .container {
    max-width: 1320px !important;
  }
  .search-main {
    padding: 145px 0 256px;
    background-position-x: 22rem; //left
    background-position-y: 2.5rem; //top
  }
}
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
}
@media screen and (min-width: 1501px) {
  .search-main {
    background-position-x: 72%; //left
  }
}
.search-title,
h4 {
  width: 490px;
  letter-spacing: 0px;
}
.search-title {
  height: 34px;
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
}
@media (max-width: 733px) {
  .search-title,
  h4 {
    width: 328px;
  }
  .search-title {
    line-height: 29px;
    height: 46px;
    font-size: 18px;
  }
}
</style>
